import { loadStripe } from '@stripe/stripe-js';
import { createContext, createMemo, createResource, createSignal, useContext } from 'solid-js';
import { colors } from '@troon/tailwind-preset/colors';
import { getConfigValue } from '../modules/config';
import type { Stripe, StripeElements } from '@stripe/stripe-js';
import type { Accessor, ParentProps } from 'solid-js';

const StripeContext = createContext<{
	stripe: Accessor<Stripe | null>;
	elements: Accessor<StripeElements | undefined>;
	setClientSecret: (secret: string) => void;
}>({ elements: () => undefined, setClientSecret: () => {}, stripe: () => null });

export function StripeProvider(props: ParentProps) {
	const [stripeResource] = createResource(() => loadStripe(getConfigValue('STRIPE_PUBLISHABLE_KEY')));
	const [clientSecret, setClientSecret] = createSignal<string | undefined>();

	const elements = createMemo(() => {
		if (!stripeResource() || !clientSecret()) {
			return;
		}

		return stripeResource()!.elements({
			fonts: [
				{
					cssSrc: 'https://fonts.bunny.net/css?family=poppins',
				},
			],
			appearance: {
				theme: 'flat',
				variables: {
					fontFamily:
						'Poppins, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
					fontSizeBase: '16px',
					spacingUnit: '4px',
					colorBackground: colors.white,
					focusBoxShadow: '#000000',
					colorPrimary: colors.brand.DEFAULT,
					colorDanger: colors.red['500'],
					colorSuccess: colors.green['500'],
					colorText: colors.neutral['950'],
					gridRowSpacing: '1.25rem',
					gridColumnSpacing: '1rem',
				},
				rules: {
					'.Input': {
						border: `1px solid ${colors.neutral.DEFAULT}`,
						borderRadius: '0.5rem',
					},
					'.Input:focus': {
						boxShadow: `${colors.brand.DEFAULT} 0px 0px 0px 1px`,
					},
					'.Label': {
						fontSize: '1rem',
						fontWeight: '500',
					},
				},
			},
			clientSecret: clientSecret(),
		});
	});

	return (
		<StripeContext.Provider value={{ elements, setClientSecret, stripe: stripeResource as Accessor<Stripe | null> }}>
			{props.children}
		</StripeContext.Provider>
	);
}

export function useStripe() {
	return useContext(StripeContext);
}
